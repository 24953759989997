import Vue from 'vue';
import Component from 'vue-class-component';
import { ApiServices } from '@/services/api.services';

@Component({})
export default class SidebarComponent extends Vue {
  public actived: any = null;
  public tabActive = false;

  public mounted() {
  }

  get stateSideBar() {
    if (!this.$store.getters.sideBar) {
      this.goBack();
    }

    return this.$store.getters.sideBar;
  }

  public close() {
    this.$store.dispatch('toggleSideBar');
    this.$store.dispatch('changeBackdrop');
    this.tabActive = false;
  }

  public toMenu(router: string) {
    this.actived = router;
    this.$router.push({ path: router });
    this.tabActive = false;
    this.close();
  }

  public toSubMenu() {
    if (!this.tabActive) {
      this.actived = '/products';
    }
    this.tabActive = !this.tabActive;
  }

  public goBack() {
    this.tabActive = false;
    this.actived = null;
  }

  public isActived(router: string) {
    return this.actived === router;
  }
}
