import Vue from 'vue';
import Component from 'vue-class-component';
import SidebarComponent from '../sidebar/sidebar.component';
import SearchComponent from '../search/search.component';
import JQuery from 'jquery';
const $ = JQuery;

@Component({
  components: {
    SidebarComponent,
    SearchComponent,
  },
})
export default class MenuHeaderComponent extends Vue {
  public search: boolean = false;
  public checkSearch: string = '';

  public mounted() {
  }

  public openSideBar() {
    this.$store.dispatch('toggleSideBar');
    this.$store.dispatch('changeBackdrop');
  }

  public closeBackdrop() {
    if (this.stateSearch) {
      this.openSearch();
    } else if (this.stateSidebar) {
      this.openSideBar();
    }
  }

  public openSearch() {
    this.checkSearch = '';
    this.$store.dispatch('changeSearch');
  }

  get stateBackdrop() {
    return this.$store.getters.backdrop;
  }

  get stateSidebar() {
    return this.$store.getters.sideBar;
  }

  get stateSearch() {
    return this.$store.getters.search;
  }

  get menuClass() {
    return this.$store.getters.classMenu;
  }
}

