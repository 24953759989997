import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {},
})
export default class DunatiLoaderComponent extends Vue {
  public mounted() {}
}

