import axios, { AxiosStatic, AxiosResponse, AxiosInstance } from 'axios';
import { DUNATI_DOMAIN } from '@/main';

export class ProjectFields {
  public slider!: { [id: string]: string };
  public chairs!: string;
  public description!: string;
  public status!: string;
  public name!: string;
  public category!: { [id: string]: string };
  public location!: string;
  // tslint:disable-next-line: variable-name
  public created_at!: string;
  public meters!: number;
  // tslint:disable-next-line: variable-name
  public updated_at!: string;
  // tslint:disable-next-line: variable-name
  public desk_series!: string;
  public img!: string;
  // tslint:disable-next-line: variable-name
  public sub_name!: string;
}

export class ProjectCategoryFields {
  public name!: string;
  // tslint:disable-next-line: variable-name
  public created_at!: string;
  // tslint:disable-next-line: variable-name
  public updated_at!: string;
}


// tslint:disable-next-line: max-classes-per-file
export class PushTableProject {
  public name!: string;
  public fields!: ProjectFields;
  public createTime!: string;
  public updateTime!: string;
  public id!: string;
}

export class PushTableProjectCategory {
  public name!: string;
  public fields!: ProjectCategoryFields;
  public createTime!: string;
  public updateTime!: string;
  public id!: string;
}

// tslint:disable-next-line: max-classes-per-file
class Api {
  public strapiUrl: string = 'https://cms.dunati.com';
  private __DOMAIN__ = process.env.NODE_ENV === 'production' ? 'https://www.dunati.com.ve' : 'https://beta.dunati.com.ve';

  private baseUrl: string = process.env.VUE_ENV === 'server'
    ? `${this.__DOMAIN__}/api`
    : '/api';

  private API_KEY_CLIENGO = '73f3c288-5814-4dcc-87e3-41dd102422f1';

  constructor() {
    // console.log(DUNATI_DOMAIN);
    axios.defaults.headers.common.Accept = 'application/json';
  }

  public getProducts(extraQuery: any = {}) {
    return axios.get(`${this.baseUrl}/products?` + extraQuery);
  }

  public getProductById(id: string) {
    return axios.get(`${this.baseUrl}/products/${id}`);
  }

  public getFileImage(id: string) {
    return `${this.baseUrl}/bucket/${id}`;
  }

  public getCategoryByCode(code: string) {
    return axios.get(`${this.baseUrl}/category/${code}`);
  }

  public getProjects(query: any = '') {
    return axios.get(`${this.baseUrl}/projects/dunati_projects?${query}`);
  }

  public getProjectsById(id: string, query: any = null) {
    const _where = (query !== null) ? `where=${JSON.stringify(query)}` : '';
    return axios.get(`${this.baseUrl}/projects/dunati_projects/${id}?${_where}`);
  }

  public getProjectsBySlug(slug: string, query: any = null) {
    const _where = (query !== null) ? `where=${JSON.stringify(query)}` : '';
    return axios.get(`${this.baseUrl}/projects/dunati_projects/by_slug/${slug}?${_where}`);
  }

  public getProjectCategories(query: any = null) {
    const _where = (query !== null) ? `where=${JSON.stringify(query)}` : '';
    return axios.get(`${this.baseUrl}/projects/dunati_projects_categories?${_where}`);
  }

  public getPosts(query: any = '') {
    return axios.get(`${this.baseUrl}/posts/dunati_posts?${query}`);
  }

  public getPostsById(id: string, query: any = null) {
    const _where = (query !== null) ? `where=${JSON.stringify(query)}` : '';
    return axios.get(`${this.baseUrl}/posts/dunati_posts/${id}?${_where}`);
  }

  public getPostsBySlug(slug: string, query: any = null) {
    const _where = (query !== null) ? `where=${JSON.stringify(query)}` : '';
    return axios.get(`${this.baseUrl}/posts/dunati_posts/by_slug/${slug}?${_where}`);
  }

  public getPostCategories(query: any = null) {
    const _where = (query !== null) ? `where=${JSON.stringify(query)}` : '';
    return axios.get(`${this.baseUrl}/posts/dunati_posts_categories?${_where}`);
  }


  //#region strapi products
  public getStrapiProducts(query: string = '') {
    return axios.get(`${this.baseUrl}/products/dunati_products?${query}`);
  }
  
  public getProductSubcategories(query: string = '') {
    return axios.get(`${this.baseUrl}/products/dunati_products/subcategories?${query}`);
  }

  public getProductBySlug(id: string, query: any = null) {
    const _where = (query !== null) ? `where=${JSON.stringify(query)}` : '';
    return axios.get(`${this.baseUrl}/products/dunati_products/by_slug/${id}?${_where}`);
  }
  //#endregion


  public sendMail(body: any = {}) {
    return axios.post(`${this.baseUrl}/send_mail`, body);
  }

  public searchAll(extraQuery: any = {}) {
    return axios.all([
      axios.get(`${this.baseUrl}/products/dunati_products?` + extraQuery),
      axios.get(`${this.baseUrl}/posts/dunati_posts?` + extraQuery),
      axios.get(`${this.baseUrl}/projects/dunati_projects?` + extraQuery),
    ]);
  }


  public contactCliengo(body: any = {}) {
    const contact = {
      name: body.name,
      message: body.message,
      email: body.email,
      status: 'new',
      phone: body.phone,
      websiteId: '57f1794be4b0c178405d2098',
      organizationName: 'Dunati',
      referalUrl: 'www.dunati.com.ve/contacto',
    };

    return axios.post(`https://api.cliengo.com/1.0/contacts?api_key=${this.API_KEY_CLIENGO}`, contact);
  }

  // tslint:disable-next-line: variable-name
  private get(data_path: string, where = null): Promise<AxiosResponse<PushTableProjectCategory>> {
    // tslint:disable-next-line: variable-name
    const _where = (where !== null) ? `where=${JSON.stringify(where)}` : '';
    // tslint:disable-next-line: max-line-length
    console.log('url: ', `${this.baseUrl}/projects/${data_path}?${_where}`);
    return axios.get(`${this.baseUrl}/projects/${data_path}?${_where}`);
  }
}

export const ApiServices = new Api();
