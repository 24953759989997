import { Subcategory } from '@/class/subcategory';
import { ApiServices } from '@/services/api.services';
import { AxiosResponse } from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sideBar: false,
    backdrop: false,
    search: false,
    classMenu: 'normal',
    projects: [
      {
        id: 'gnl-penco',
        name: 'GNL Penco',
        sub_name: 'Oficinas Espacio Foster',
        description: 'Este proyecto sin duda alguna, es la representación de sus ideales. Sus espacios son la conceptualización de la energía y la seguridad. El mobiliario de oficina escogido fue serie Diamond, que brinda al espacio pureza y potencia. Sus oficinas cuentan con estaciones de trabajo individuales y grupales, también con mesas de reunión.',
        meters: 420,
        location: 'Espacio Foster, Las Condes',
        chairs: 'FOCUS - ARCHI - TERA',
        logo: '/assets/images/projects-detail/gnl-penco/gnl-penco-instalación-muebles-de-oficina-chile.png',
        img: '/assets/images/projects-categories/gnl-penco.jpg',
        slider: {
          1: '/assets/images/projects-detail/gnl-penco/Recepción-de-oficina-en-Chile.jpg',
          2: '/assets/images/projects-detail/gnl-penco/Escritorio-y-silla-de-oficinas-Dunati-en- Chile.jpg',
          3: '/assets/images/projects-detail/gnl-penco/Escritorios-bench-de-oficinas-en-Chile.jpg',
          4: '/assets/images/projects-detail/gnl-penco/Mesa-de-reunión-Dunati-para-oficinas-en-Santiago,- Chile.jpg',
          5: '/assets/images/projects-detail/gnl-penco/Mesa-de-reunión-oficina-en-Chile.jpg',
          6: '/assets/images/projects-detail/gnl-penco/Muebles-de-oficina-áreas-comunes.jpg',
          7: '/assets/images/projects-detail/gnl-penco/Muebles-de-oficina-comerdor.- Chile.jpg',
        },
        desk_series: 'DIAMOND',
        categories: ['Otros', 'Industrias'],
        created_at: '2019-07-29T22:23:26.344908Z',
        img_card: '',
        rate: '4.5',
      },
      {
        id: 'achs',
        name: 'ACHS',
        sub_name: 'Oficinas Vicuña Mackenna, Providencia',
        description: 'Un proyecto donde resaltan texturas y colores. Nuestra serie versa fue escogida para este espacio de trabajo en donde el look es juvenil y moderno. La mezcla de colores y las terminaciones minimalistas están acompañadas de nuestras mejores sillas axis.',
        meters: 700,
        location: 'Providencia, Santiago',
        logo: '/assets/images/projects-detail/achs/Logo_ACHS 300.png',
        img: '/assets/images/projects-categories/proyectos-de-muebles-de-oficinas-para-ACHS-en-Chile.jpg',
        slider: {
          1: '/assets/images/projects-detail/achs/Muebles-de-oficinas-ACHS-en-Providencia-Santiago-Chile-01.jpg',
          2: '/assets/images/projects-detail/achs/Muebles-de-oficinas-ACHS-en-Providencia-Santiago-Chile-02.jpg',
          3: '/assets/images/projects-detail/achs/Muebles-de-oficinas-ACHS-en-Providencia-Santiago-Chile-03.jpg',
          4: '/assets/images/projects-detail/achs/Muebles-de-oficinas-ACHS-en-Providencia-Santiago-Chile-04.jpg',
          5: '/assets/images/projects-detail/achs/Muebles-de-oficinas-ACHS-en-Providencia-Santiago-Chile-05.jpg',
          6: '/assets/images/projects-detail/achs/Muebles-de-oficinas-ACHS-en-Providencia-Santiago-Chile-06.jpg',
          7: '/assets/images/projects-detail/achs/Muebles-de-oficinas-ACHS-en-Providencia-Santiago-Chile-07.jpg',
        },
        desk_series: 'VERSA - SILLAS AXIS',
        categories: ['Otros'],
        created_at: '2019-07-29T22:23:26.344908Z',
        img_card: '',
        rate: '5',
      }, {
        id: 'dsv',
        name: 'DSV',
        sub_name: 'Oficina Las Condes, Santiago',
        description: 'Un proyecto en donde el espacio debía ser muy bien utilizado y aprovechado. El uso de múltiples pantallas y teléfonos se transformó en un desafío eléctrico, que fue solucionado con nuestras bandejas y tapas eléctricas. El color corporativo fue aplicado en tiradores de cajoneras metálicas móviles, y en nuestra gran recepción. Este proyecto fue desarrollado por Aileen Irazábal, arquitecto habilitador.',
        meters: 820,
        location: 'Apoquindo, Las Condes',
        logo: '/assets/images/projects-detail/dsv/Logo-DSV.png',
        img: '/assets/images/projects-categories/proyecto-de-oficina-dsv-en-chile.jpg',
        slider: {
          1: '/assets/images/projects-detail/dsv/proyecto-muebles-de-oficina-escritorios-01.jpg',
          2: '/assets/images/projects-detail/dsv/proyecto-muebles-de-oficina-escritorios-02.jpg',
          3: '/assets/images/projects-detail/dsv/proyecto-muebles-de-oficina-escritorios-03.jpg',
          4: '/assets/images/projects-detail/dsv/proyecto-muebles-de-oficina-escritorios-04.jpg',
          5: '/assets/images/projects-detail/dsv/proyecto-muebles-de-oficina-escritorios-05.jpg',
          6: '/assets/images/projects-detail/dsv/proyecto-muebles-de-oficina-escritorios-06.jpg',
        },
        desk_series: 'VERSA - SILLAS FOCUS',
        categories: ['Otros'],
        created_at: '2019-07-29T22:23:26.344908Z',
        img_card: '',
        rate: '3.1',
      },
      {
        id: 'cowork-latam',
        name: 'COWORK LATAM',
        sub_name: 'Oficinas Las Condes',
        description: 'Con el concepto de unidad, colaboración y elaboración de redes, cowork Latam opta por nuestra serie Versa, número uno en adaptación. Utiliza escritorios de trabajo modulares, creando espacios de trabajo en donde la comunicación se potencia gracias a la eficacia del mobiliario de trabajo. La conectividad es un punto fundamental para este cowork, nos encargamos de la solución para la conectividad de red y electricidad',
        meters: 600,
        location: 'LAS CONDES, SANTIAGO',
        chairs: '',
        logo: '/assets/images/projects-detail/cowork-latam/logo-Cowork-muebles-de-oficina.png',
        img: '/assets/images/projects-detail/cowork-latam/Latam-Cowork-muebles-de-oficina-portada.jpg',
        slider: {
          1: '/assets/images/projects-detail/cowork-latam/Cowork-Latam-muebles-de-oficina-escritorios-tipo-bench-Santiago,-Chile.jpg',
          2: '/assets/images/projects-detail/cowork-latam/Cowork-Latam-muebles-de-oficina-escritorios-bench-Santiago,-Chile.jpg',
          3: '/assets/images/projects-detail/cowork-latam/Cowork-Latam-muebles-de-oficina-bench-Santiago,-Chile..jpg',
          4: '/assets/images/projects-detail/cowork-latam/Cowork-Latam-muebles-de-oficina-bench-Santiago,-Chile.jpg',
          5: '/assets/images/projects-detail/cowork-latam/Cowork-Latam-muebles-de-oficina-escritorios-bench-Santiago,-Chile..jpg',
        },
        desk_series: 'VERSA - NORDIKA - COOPER - GRETA',
        categories: ['Cowork', 'Otros'],
        created_at: '2018-07-24T01:50:30.603044Z',
        img_card: '',
        rate: '4.3',
      },
      {
        id: 'te-i',
        name: 'Te-i',
        sub_name: 'Oficinas Las Condes',
        description: 'Con el uso de sus colores corporativos, los espacios de trabajo de Te-i fueron demarcados por áreas, utilizando nuestras cajoneras como principal señal, gracias a los colores que se pueden escoger para los tiradores. Este proyecto cuenta con escritorios grupales de trabajo, manteniendo orden y privacidad con separadores de cristal.',
        meters: 390,
        location: 'Las Condes, SANTIAGO',
        chairs: 'AXIS - COOPER - GIA - SOHO',
        logo: '/assets/images/projects-detail/te-i/Te-i-proyecto-de-muebles-y-escritorios-de-oficina.png',
        img: '/assets/images/projects-categories/te-i.jpg',
        slider: {
          1: '/assets/images/projects-detail/te-i/Te--i-escritorios-bench-y-sillas-de-oficinas-en-Chile..jpg',
          2: '/assets/images/projects-detail/te-i/Te--i-escritorios-bench-y-sillas-de-oficinas-en-Santiago.jpg',
          3: '/assets/images/projects-detail/te-i/Te--i-escritorios-y-sillas-de-oficinas-en-Santiago..jpg',
          4: '/assets/images/projects-detail/te-i/Te--i-mesas-de-reuniones-para-oficinas-en-Santiago.jpg',
          5: '/assets/images/projects-detail/te-i/Te--i-espacios-comunes-de-oficinas-en-Chile.jpg',
          6: '/assets/images/projects-detail/te-i/Te--i-poltronas-Gia-para-oficinas.jpg',
          7: '/assets/images/projects-detail/te-i/Te--i-recepción-para-oficinas-, Chile..jpg',
        },
        desk_series: 'VERSA - NORDIKA',
        categories: ['Otros'],
        created_at: '2020-07-24T01:50:30.603044Z',
        img_card: '',
        rate: '4.4',
      },
      {
        id: 'landspace',
        name: 'Landspace',
        sub_name: 'Oficinas Las Condes',
        description: 'Un proyecto donde resaltan texturas y colores. Nuestra serie nordika fue escogida para este espacio de trabajo en donde el look es juvenil y moderno. La mezcla de colores y las terminaciones minimalistas están acompañadas de nuestras mejores sillas. Con la capacidad de regulación y adaptación de nuestra silla ejecutiva Axis, sumado al diseño industrial de nuestra silla multipropósito Greta, es que este proyecto es en totalidad diseño y ergonomía.',
        meters: 179.54,
        location: 'Las Condes, SANTIAGO',
        chairs: 'AXIS - COOPER - GRETA',
        logo: '/assets/images/projects-detail/a3/A3-instalación-de-muebles-de-oficina-chile.png',
        img: '/assets/images/projects-categories/landspace.jpg',
        slider: {
          1: '/assets/images/projects-detail/a3/A3-muebles-de-oficina-Santiago,-Chile.jpg',
          2: '/assets/images/projects-detail/a3/A3-muebles-de-oficina-tipo-bench-Santiago,-Chile..jpg',
          3: '/assets/images/projects-detail/a3/A3-muebles-de-oficina-escritorios-bench-Santiago,-Chile..jpg',
          4: '/assets/images/projects-detail/a3/A3-muebles-de-oficina-escritorios-bench-Santiago,-Chile.jpg',
          5: '/assets/images/projects-detail/a3/A3-escritorios-de-oficina-Santiago,-Chile.jpg',
          6: '/assets/images/projects-detail/a3/A3-sillas-de-oficina-Santiago,-Chile.jpg',
        },
        desk_series: 'NORDIKA',
        categories: ['Otros'],
        created_at: '2017-07-24T01:50:30.603044Z',
        img_card: '',
        rate: '4.2',
      },
      {
        id: 'lavin-abogados',
        name: 'Lavin Abogados',
        sub_name: 'Oficinas Las Condes',
        description: 'Simplicidad, pulcritud y elegancia, es lo que quisimos retratar como concepto para las oficinas de Lavin Abogados. Utilizamos blanco como color principal, y detalles en chapa de madera para espacios de reunión y conferencia. El retrato de sus ideales fue un punto fundamental dentro del proyecto, creamos espacios que reflejen honestidad y confianza.',
        meters: 260,
        location: 'LAS CONDES, SANTIAGO',
        chairs: 'WORK UP - YES - ATTON',
        logo: '/assets/images/projects-detail/lavin/Lavin-instalación-de-mobiliario-de-oficina..png',
        img: '/assets/images/projects-categories/lavin.jpg',
        slider: {
          1: '/assets/images/projects-detail/lavin/Lavín-Abogados-mesa-de-reunión-Santiago,-Chile.jpg',
          2: '/assets/images/projects-detail/lavin/Lavín-Abogados-mesa-de-reunión-mediana-Santiago,-Chile.jpg',
          3: '/assets/images/projects-detail/lavin/Lavín-Abogados-muebles-de-oficina-Santiago,-Chile.jpg',
          4: '/assets/images/projects-detail/lavin/Lavín-Abogados-mesa-de-reunión-grande-Santiago,-Chile.jpg',
        },
        desk_series: 'VERSA',
        categories: ['Bancos', 'Otros'],
        created_at: '2016-07-24T01:50:30.603044Z',
        img_card: '',
        rate: '3',
      },
      {
        id: 'tambo',
        name: 'Tambo',
        sub_name: 'Oficinas Rancagua',
        description: 'Este proyecto sin duda alguna, es la representación de sus ideales. Sus espacios son la conceptualización de la energía y la seguridad. El mobiliario de oficina escogido fue serie Diamond, que brinda al espacio pureza y potencia. Sus oficinas cuentan con estaciones de trabajo individuales y grupales, también con mesas de reunión.',
        meters: 179.54,
        location: 'Rancagua, VI del Libertador Bernardo O\'higgins',
        chairs: 'Leep',
        logo: '/assets/images/projects-detail/tambo/Tambo-instalación-de-escritorios-de-oficina.png',
        img: '/assets/images/projects-categories/tambo.jpg',
        slider: {
          1: '/assets/images/projects-detail/tambo/Tambo-muebes-de-oficina-escritorios-Rancagua,-Chile.jpg',
          2: '/assets/images/projects-detail/tambo/Tambo-muebles-de-oficina-bench-Rancagua,-Chile.jpg',
          3: '/assets/images/projects-detail/tambo/Tambo-muebles-de-oficina-escritorios-Rancagua-,Chile.jpg',
          4: '/assets/images/projects-detail/tambo/Tambo-muebles-de-oficina-escritorios-Rancagua,-Chile.jpg',
          5: '/assets/images/projects-detail/tambo/Tambo-muebles-de-oficina-Rancagua-,Chile.jpg',
        },
        desk_series: 'VERSA - DIAMOND',
        categories: ['Ingeniería', 'Otros'],
        created_at: '2021-07-24T01:50:30.603044Z',
        img_card: '',
        rate: '3.5',
      },
    ],
    blogs: [
      {
        id: 'el-color-de-la-oficina',
        categories: ['Tendencias'],
        cover: '/assets/images/blog-page/El-color-de-tu-oficina.jpg',
        title: 'El color de la oficina',
        summary: 'El color de la oficina va más allá de lo estético. El color con el que ambientas tu empresa puede mejorar el ambiente laboral y ayudar a los que que trabajan en ella a hacerlo de mejor forma.',
        post_date: '26/12/2019',
        rate: '5',
        posts: [
          {
            layout: 'normal',
            img: '/assets/images/blog-detail/el-color-de-la-oficina/oficinas-color-rojo.jpg',
            subtitle: 'EL COLOR ROJO',
            text: `<p>El color en una oficina es crucial no solo por estética. Las tonalidades que escoges ayudan a tu marca a establecerse y afectan
              directamente en cómo se siente el ambiente y tus trabajadores, pudiendo ser una herramienta muy eficaz para crear lugares de trabajo más
              felices, concentrados, creativos, objetivos, callados o incluso más eficientes. Te damos una breve revisión de los colores y cómo estos
              afectan en una oficina.</p>
              <p>El rojo es un color que representa pasión, fuerza, peligro, atención y poder. Es el primer color que nuestros ojos captan en un lugar y,
              debido a que estimula el ritmo cardiaco y la respiración, es una gran opción para hacer que las personas tomen decisiones rápidas. Sin
              embargo, durante negociaciones, puede crear confrontación.</p>

              <p>Lo ideal es que, en vez de diseñar una oficina entera de este color, solo se utilicen en puntos decorativos como el color de las sillas,
              lámparas, esculturas, o accesorios pequeños. Queda muy bien si se utiliza en una sola pared, dando realce a un sector de la oficina, sin
              generar estrés debido a una sobre estimulación.</p>

              <img src="/assets/images/blog-detail/COMILLAS-BLOG.svg">

              <p>Mezclar el rojo con blanco, grises y negros es una paleta que te asegura un equilibrio de intensivas y elegancia.</p>`,
          },
          {
            layout: 'normal',
            img: '/assets/images/blog-detail/el-color-de-la-oficina/oficinas-amarillas-y-naranjas.jpg',
            subtitle: 'LOS COLORES AMARILLO Y NARANJA',
            text: `
              <p>El color amarillo es gran generador de energía. Se le asocia con la felicidad y el optimismo, además de ser el segundo color que nuestra retina capta. Su capacidad de estimulación es tan grande como el rojo, así que es aconsejable tener espacios que lo ocupen de forma puntual y acotada para no generar la sensación de inquietud e incomodidad en las personas.</p>
              <p>El naranja, por otra parte, es un gran potenciador de la comunicación y el trabajo en equipo. Logra, además, espacios conciliadores, por lo que en salas de reuniones es aconsejable tener pequeños puntos con este color.</p>
              <p>Para equilibrar su calidez se aconseja mezclar con grises o celestes que regulen la intensidad del tono. Si el amarillo o naranja se combinan con negro, se pueden lograr espacios modernos perfectos para generar desafíos.</p>
              <img src="/assets/images/blog-detail/COMILLAS-BLOG.svg>
              <p>En comedores y zonas para almorzar, la mezcla de amarillo y naranja es ideal ya que también se le asocian a la sensación de hambre.</p>`,
          },
          {
            layout: 'normal',
            img: '/assets/images/blog-detail/el-color-de-la-oficina/oficinas-azules.jpg',
            subtitle: 'EL COLOR AZUL',
            text: `
              <p>El azul es por excelencia el color más usado para oficinas. No es casualidad que las empresas de bancos, aseguradoras, transnacionales y empresas que necesitan generar una confianza extrema en sus clientes lo elijan como color base en sus marcas. El azul inspira confianza, importancia, seriedad.</p>
              <p>En ambientes, el azul otorga concentración, serenidad, calma y da sensación de grandeza y autoridad. Es un color asociado a la estabilidad tanto emocional como financiera, por lo que es una muy buena opción para tener presente en las paredes y en la decoración de nuestras oficinas.</p>
              <p>Se entiende además como un color honesto que inspira sabiduría. Sin embargo, mezclado con los tonos correctos, como blanco o gris, puede dar la apariencia de una oficina extremadamente moderna y vanguardista. Es un color muy diplomático y solemne; favorito para lugares donde se van a recibir visitas con altos grados como oficinas de ministerios, presidencias y directorios.</p>
              `,
          },
          {
            layout: 'normal',
            img: '/assets/images/blog-detail/el-color-de-la-oficina/oficinas-color-verde.jpg',
            subtitle: 'EL COLOR VERDE',
            text: `
              <p>El color que más nos acerca a la naturaleza y a la tranquilidad es el verde. Es muy indicado para lugares operativos donde existe trabajo grupal. Nos ayuda a relajarnos y además a desconectarnos durante un momento del trabajo intenso, siendo muy efectivo para combatir el estrés, dar seguridad y confianza e incluso hacernos sentir mejor físicamente, ya que en lugares donde no tenemos vista a zonas verdes, es una gran opción para atenuar el agobio que se genera.</p>
              <p>La creatividad es otra parte de nuestras capacidades que se activa de mejor manera cuando este color está presente en la oficina. Muchos aprovechan alfombras tipo shaggy color verde, para crear un efecto de pasto en sus oficinas y dar espacios de relajación y distención a sus trabajadores.</p>
              `,
          },
          {
            layout: 'normal',
            img: '/assets/images/blog-detail/el-color-de-la-oficina/oficinas-color-turquesa.jpg',
            subtitle: 'EL COLOR CELESTE Y TURQUESA',
            text: `
              <p>El celeste es el intermediario entre el azul y el verde. Esto hace que tenga un poco de ambos colores. Genera concentración y tranquilidad, que provienen de la seguridad que nos otorga el azul y al mismo tiempo una sensación de relajo y apoyo que proviene del verde.</p>
              <p>Este color es ideal para lugares donde se necesita que un trabajador este relajado y enfocado en su trabajo. Mas no es aconsejable abusar del celeste puesto que la cantidad de relajo que nos trae este color, agregado a la sensación de calma, puede generar sueño. Pequeños toques que lo iluminen como blancos o amarillos son ideales para ocupar este color sin problemas.</p>
              <p>El turquesa también esta como intermediario entre estos colores. Sin embargo, se alinea más con el verde. Es un color que ha sido tendencia en los últimos años y ha sorprendido por su capacidad de generar innovación, creatividad y sensación de modernidad. Es un color fresco y fácil de combinar con blancos, amarillos, grises y naranjas.</p>
              `,
          },
          {
            layout: 'normal',
            img: '/assets/images/blog-detail/el-color-de-la-oficina/oficinas-color-cafe.jpg',
            subtitle: 'EL COLOR CAFE',
            text: `
              <p>El café es uno de los colores más clásicos para la oficina. Es un color que puede transformar enteramente un espacio y dar una apariencia sumamente elegante e imponente. Los colores tierra como el beige, arena, tonalidades marrones y las maderas son colores que se asocian con la tradición, la confianza, estabilidad y la seguridad. Es una excelente opción para lugares con altos cargos donde la concentración y un espacio tranquilo son necesarios.</p>
              <p>Los tonos oscuros se recomiendan en lugares espaciosos y con buena entrada de luz natural, ya que de lo contrario pueden dar la apariencia visual de que la oficina es más pequeña. Los tonos claros pueden ser usados tanto en espacios grandes como pequeños ya que aportan luminosidad y hacen ganar espacio visual. Al igual que el celeste, son colores que no deben usarse en exceso, puesto que el grado de relajación que provocan podría causar sueño o aburrimiento. No obstante, su uso equilibrado puede crear un ambiente perfecto.</p>
              <p>Se puede mezclar con prácticamente cualquier color y lograr un espacio con un toque sofisticado, elegante y al mismo tiempo renovado y fresco, como por ejemplo, al combinarlo con los amarillo, verde, turquesa o blanco. Lo más útil de este color es que puede emplearse para el color de paredes o bien de los accesorios, cortinas, puertas, mobiliario, alfombras, etc.</p>
              `,
          },
          {
            layout: 'normal',
            img: '/assets/images/blog-detail/el-color-de-la-oficina/oficinas-color-blanco.jpg',
            subtitle: 'EL COLOR BLANCO',
            text: `
              <p>El color blanco nos atrae hacia la sensación de limpieza, elegancia, y nos entrega un alivio visual como ningún otro color. El blanco nos aporta una gran cantidad de luz y es un muy buen color a considerar tanto para espacios pequeños como grandes.</p>
              <p>Su mejor característica es que puede ser combinado con todos los colores que hay tanto en mobiliario como accesorios, o bien seleccionar solo una pared para que contraste con este color.</p>
              <p>El blanco se recomienda en zonas de trabajo intenso. Además, si existe un espacio en la oficina que esté saturada de objetos y colores, es una opción para evitar la fatiga visual. Si bien el blanco no estimula la creatividad en las personas, si funciona para ser más objetivos y trabajar de forma más concentrada.</p>
              `,
          },

        ],
      },
      {
        id: 'silla-ergonomica',
        categories: ['Ergonomia'],
        cover: '/assets/images/blog-page/Sillas-de-Oficina-Ergonomicas.jpg',
        title: '¿Es mi silla realmente ergonómica?',
        summary: '8 maneras de saberlo. La silla es donde el trabajador se encuentra la mayor parte del su jornada laboral. Es por eso que no saber escoger la adecuada puede traer problemas de salud e incluso de productividad.',
        post_date: '26/09/2019',
        rate: '4.15',
        posts: [
          {
            layout: 'normal',
            img: '',
            subtitle: '8 MANERAS DE SABERLO...',
            text: `
              <p>La <a href="https://www.dunati.com.ve/productos/silla-axis-sin-cabecero">silla</a> representa el objeto donde el trabajador se encuentra la mayor parte del tiempo durante su jornada laboral; es por eso que no saber escoger la adecuada puede causar problemas de salud e incluso afectar la productividad del empleado. En DUNATI queremos ayudarte a saber en qué debes fijarte realmente para poder escoger el asiento correcto, asegurándote así lo que tú y tu oficina de verdad necesitan.</p>
              <p>1. Lo primero que se debe considerar es el <strong>apoyo lumbar</strong> que esta tenga, entendiéndose que no es solo un mecanismo que soporta la espalda; debe, además, estar diseñada para asemejarse lo más posible a la curva natural de la columna. Existen sillas con cojines o almohadillas por detrás de la malla de respaldo que proporcionan un soporte en la espalda y, en algunos modelos, estas pueden regularse en altura o la tensión que generan, dándole al usuario una completa capacidad de personalizar su silla, hasta que esta se ajuste a él.</p>
              <p>2. La <strong>reclinación</strong> es indispensable para asegurar un movimiento continuo de la columna, evitando las posiciones rígidas. Siempre es mejor elegir una silla sincrónica a una reclinable, puesto que esta última mueve tanto el asiento como el espaldar de forma conjunta. Mientras que una sincrónica mueve el asiento solo cuando el espaldar ha alcanzado cierto grado de inclinación, logrando que el trabajador pueda estrecharse o inclinar la espalda sin sentir que las piernas se levantan al mismo tiempo.</p>
              `,
          },
          {
            layout: 'left',
            img: '/assets/images/blog-detail/es-mi-silla-ergonomica/silla-de-oficina-ergonomica-axis.jpg',
            subtitle: '',
            text: `
              <p>3. La <strong>altura debe ser regulable</strong>, para lograr un ángulo entre 90º y 120° para la flexión de las rodillas. No es aconsejable que la altura sea muy baja, pues provocaría que la persona coloque los pies debajo de la silla. Los pies deben tocar el suelo y, si es necesario, se debe tener un reposapies para compensar.</p>
              <p>4. El <strong>grosor y material del tapizado</strong> también deben evaluarse. No debe ser extremadamente acolchado ya que eso solo logrará que con el tiempo se deforme, adaptándose a cualquier mala postura que el usuario pudiese tener. La tela debe ser transpirable, para que no genere humedad y soporte un uso continuo, garantizando su durabilidad.</p>
              <p>5. El <strong>cojín de asiento</strong> debe ser curvo hacia el borde, donde se ubican las rodillas, generando una caída suave de las piernas. De esta forma se evitará la presión sobre los nervios de los muslos y posteriores entumecimientos, várices y enfriamiento de las extremidades.</p>
              `,
          },
          {
            layout: 'right',
            img: '/assets/images/blog-detail/es-mi-silla-ergonomica/silla-de-oficina-ejecutiva-axis.jpg',
            subtitle: '',
            text: `
            <p>6. Los <strong>apoya brazos</strong> son un complemento fundamental, ya que en estos se vierte el peso del trabajador, aliviando la columna vertebral y evitando la sensación de hombros rígidos. Deben ser ajustables al menos en altura y, en lo posible, también en el ancho y las posaderas con mecanismo de rotación, para que se ajusten al cuerpo de la persona que la use.</p>
            <p>7. La <strong>movilidad de la silla</strong> es un factor que facilita el desplazamiento de una persona, brindándole comodidad. Por eso, que cuente con ruedas para piso duro, laminado o madera es un aporte tanto a la ergonomía como al cuidado del inmueble.</p>
            <p>8. Un <strong>cabecero</strong> es el plus ideal a la hora de descansar en una silla, ayudando a liberar presión innecesaria en el resto del cuerpo y brindando comodidad al cuello. Sin embargo, no es aconsejable que se utilice en todo momento.</p>
            `,
          },
        ],
      },
      {
        id: 'el-mobiliario-de-oficina-tipo-cowork',
        categories: ['Tendencias', 'Colaboraciones'],
        cover: '/assets/images/blog-page/Muebles-de-Oficina-Bench.jpg',
        title: 'El sistema cowork',
        summary: 'El sistema CoWork es una forma de trabajo colaborativo que promueve el intercambio de ideas para que cada trabajador pueda realizar sus tareas y proyectos de forma más eficiente.',
        post_date: '26/09/2019',
        rate: '4.12',
        posts: [
          {
            layout: 'right',
            img: '/assets/images/blog-detail/el-sistema-cowork/escritorios-de-oficinas-tipo-bench-en-chile.jpg',
            subtitle: '“…Si tú tienes una idea y yo tengo una idea e intercambiamos ideas, entonces ambos tendremos dos ideas”.  - George Bernad Shaw.',
            text: `
              <p>Pensemos en una oficina con escritorios individuales; espacios cerrados; gente enfocada solo en su trabajo, a pesar de que hayan varios trabajadores haciendo una tarea similar; la necesidad de salir cada 20 minutos a estirar las piernas; no poder terminar una tarea porque sentimos que se nos acaba la inspiración, creatividad o simplemente porque ya no se nos ocurren más ideas. Todo esto tiene un nombre: “Oficina Tradicional” y es la forma en la cual se llevan muchos años trabajando.</p>
              <p>Afortunadamente, desde hace poco más de dos años se ha desarrollado en el mundo un concepto de trabajo que analiza estos problemas y nos hace meditar sobre aspectos que damos por sentado, como lo son los colores tradicionales, la diagramación típica de los puestos de trabajo y hasta los techos repletos de focos incandescentes.</p>
              <p>Esta idea nos trae lo que pareciera ser la solución indicada para hacer ver más bonitas las oficinas y también lograr lugares de trabajo eficientes que permitan al personal desarrollar todo su potencial, maximizando desde la innovación hasta el compromiso que cada persona puede llegar a sentir con la empresa.</p>
              <p>El sistema <strong>Cowork</strong> es un método de trabajo colaborativo que promueve el intercambio de ideas, para que cada trabajador pueda realizar sus tareas y proyectos de forma más eficiente, mejorando su rendimiento, resultados e incluso su ánimo y compromiso laboral.</p>
              `,
            },
            {
              layout: 'normal',
              img: '/assets/images/blog-detail/el-sistema-cowork/escritorios-de-oficina-para-cowork.jpg',
              subtitle: '',
              text: `
                <p>Para hacer posible todo esto, se debe considerar que el espacio influye de manera directa. Ahí no solo hablamos de la decoración y accesorios que complementan una oficina si no, además, de los espacios y superficies donde se realizan las tareas. Tener todos los puestos de trabajo sectorizados y excesivamente cerrados con divisiones que no permitan que las ideas fluyan de persona a persona es contraproducente para lograr que el ingenio vaya enriqueciéndose entre los trabajadores. Por eso, el mobiliario base, como los escritorios, deben tener el espacio suficiente para que el grupo de personas pueda sentarse cómodamente a desarrollar sus actividades, tanto de forma grupal como de forma individual cuando sea necesario, otorgando con esto espacios de trabajo cómodos y funcionales.</p>
                <p>Las medidas mínimas que se deben tener en cuenta para hacer un trabajo individual de forma cómoda por parte de un usuario promedio son 120cm de ancho por 60cm de profundidad. Para trabajos colaborativos el espacio de la cubierta para cada individuo debería ser de 140cm por 70cm de profundidad. Y deben estar entre los 70 y 74 centímetros de altura.</p>
                <p>El trabajador actual contempla en su pack de herramientas celulares, laptops, tablets y diversos elementos electrónicos, además de cuadernos, carpetas, impresiones, etc. Lo idóneo es que cada objeto pueda estar situado en la cubierta de forma que no provoque problemas de espacio y que no existan choque con cables, cargadores o cualquier elemento que genere una molestia para desarrollar el trabajo. Si el mobiliario contempla pasacables, mucho mejor pues se maximiza el espacio útil de cubierta y se evitan accidentes.</p>
                <p>Para espacios de trabajo grupales, lo mejor son las estaciones de trabajo frontales, también conocidas como estaciones bench y estaciones de trabajo laterales. Estas permiten que exista un espacio de trabajo tanto individual, definido por el porte de cada cubierta o complementos delimitadores y al mismo tiempo permite que el trabajo pueda realizarse en conjunto con el resto del equipo.</p>
                <p>Para aquellos lugares que son utilizados por diferentes empresas durante el día y no existe un control o un equipo fijo en un solo lugar, la opción de almacenamiento siempre se vuelve un tema complicado.</p>
                `,
              },
              {
                layout: 'normal',
                img: '/assets/images/blog-detail/el-sistema-cowork/espacio-cowork-para-oficinas-en-chile.png',
                subtitle: '',
                text: `
                  <p>La mejor solución en estos casos son muebles de almacenamiento con cerradura, asumiendo que no es el caso de una oficina arrendada por horas. De serlo, considerar mobiliario con espacios de almacenamiento abierto es una gran idea para mantener las cosas ordenadas por el tiempo que se necesiten y una forma de dejar las cosas a la vista para que no se nos olvide ningún documento.</p>
                  <p>De todas formas, cada vez es mayor la cantidad de empresas que trabajan con menos papel y más con documentos virtuales que almacenan en nubes online, por lo que casilleros para las pertenencias personales es probablemente lo más necesario.</p>
                  <p>Los asientos pasan por un cuestionamiento muy similar, sin embargo, la opción de una silla ergonómica que contemple sistema sincrónico, altura de asiento, espaldar y apoya brazos regulable son las cuatro principales características que cualquier asiento debería tener, asegurando que podrá ser útil a cualquier usuario. Hay que fijarse también en la calidad del tapizado, malla y base para asegurar una buena vida útil, a pesar de su uso prolongado.</p>
                  <p>¿Y sobre la privacidad?</p>
                  <p>Abusar de los espacios abiertos tampoco es una buena opción. Existen trabajos o tareas que se hacen de mejor forma cuando existe delimitación de ciertos espacios. También hay personas que se sienten más cómodos en su lugar de trabajo si no están demasiado expuestas acústica o visualmente.</p>
                  <p>Si es necesario crear delimitaciones de trabajo siempre es buena opción pensar en paneles divisores de vidrio, los cuales pueden otorgar mayor privacidad sin restar amplitud al espacio. Se pueden considerar tanto como una opción para paredes como para las cubiertas de los escritorios. Un panel de 30cm de altura sobre cubierta generará una buena delimitación tanto visual como de espacio personal sin disminuir el flujo de ideas y de las conversaciones.</p>
                  `,
                },
        ],
      },
      {
        id: 'una-silla-de-oficina-que-planta-arboles',
        categories: ['Ergonomia', 'Colaboraciones', 'Novedades'],
        cover: '/assets/images/blog-detail/una-silla-que-planta-arboles/Una-silla-de-oficina-que planta-arboles.jpg',
        title: 'Una silla de oficina que planta arboles',
        summary: 'Conocer el impacto de nuestros actos y traducirlos a huella de carbono, nos motivó a comenzar este proyecto, y queremos que seas parte de él.',
        post_date: '26/09/2019',
        rate: '5',
        posts: [
          {
            layout: 'left',
            img: '/assets/images/blog-detail/una-silla-que-planta-arboles/Muebles-de-oficina-ecologicos.jpg',
            subtitle: '',
            text: `
              <p>¿Realmente sabemos el daño que causamos al planeta? ¿Cuál es el impacto de nuestros actos cotidianos en el medio ambiente? ¿Conocemos nuestra huella de carbono? Muchos de estos daños son irreparables, animales en extinción, daños en los ecosistemas, contaminación del agua y más.</p>
              <p>La huella de carbono es el conjunto de gases de efecto invernadero que hemos provocado con actos cotidianos como: usar medios de transportes de combustión, viajes aéreos, consumir y comprar ciertos alimentos, shopping en tiendas de indumentaria, construcción, incluso el consumo de agua, electricidad, gas.</p>
              <p>El hombre tiene una responsabilidad indiscutible ante el cambio climático, el alza de la temperatura registrada desde mediados del siglo XX, se atribuye al aumento de la huella de carbono. Nosotros, los seres humanos, estamos terminando con el único vínculo que es capaz de unirnos, la tierra.</p>
              <p>¿Estamos a tiempo de girar el timón? Nuestra respuesta es SI, mejorando ciertos habitos en nuestra rutina, que pueden poco a poco ir contribuyendo al equilibrio y a la recuperación de ecosistemas.</p>
              <p>Frente al cambio climático y el aumento de emisión de CO2, hay varias cosas que podemos hacer.</p>
              <p>Una de ellas es la regla de las 3 erres, esto quiere decir, reduce, reutiliza y recicla. Este concepto hace referencia específicamente a reducir el volumen de residuos o basura generada, con esto se pretende desarrollar hábitos de consumo responsable y concientizar a las personas para que de esa manera reduzcan su huella de carbono.</p>
              <p>También, existe la reforestación, los árboles nativos son capaces de regular la temperatura y limpiar el aire, filtrar el agua y proteger las napas subterráneas, entre otras características.  Permiten también el hábitat para la vida silvestre y la absorción de CO2. Ellos son nuestros grandes aliados y los encargados de producir oxígeno.</p>
              `,
          },
          {
            layout: 'right',
            img: '/assets/images/blog-detail/una-silla-que-planta-arboles/Silla-de-oficina-ecologica.jpg',
            subtitle: '',
            text: `
              <p>Como empresa compartimos el sentir de responsabilidad, es por eso que queremos anunciar la decisión que hemos tomado. Gracias al apoyo de nuestros aliados, la Fundación Reforestemos, Dunati Chile planta árboles mensualmente, gracias a la preferencia de los clientes por nuestras sillas y muebles de oficinas.</p>
              <p>El propósito de Dunati es beneficiar y aportar a la reforestación por medio de nuestra <a href="https://www.dunati.com.ve/productos/silla-axis-blanca-sin-cabecero">silla ejecutiva axis blanca</a>. Por medio de ella, nuestra plantación de bosques irá aumentando mes a mes. Nuestra silla axis será el producto verde de Dunati. Al preferirla, estarás plantando un árbol para Chile y además estarás escogiendo todos los atributos que una silla ergonómica debe tener.</p>
              <p>La detención del cambio climático es un constante aprendizaje; como empresa de muebles para oficinas, nos comprometemos a mejorar nuestros procesos y nuestros actos.  La calidad de nuestros productos ha estado en constante mejora, de esa forma la compra de muebles para tu oficina, no será un gasto que debas desechar en un par de años más, si no que se transforme en una inversión que no necesite renovación por muchos años. </p>
              <img src="/assets/images/blog-detail/COMILLAS-BLOG.svg">
              <p>La calidad de nuestros muebles y escritorios de oficina será un pilar fundamental en el propósito de reducir y reutilizar, así también la fabricación de nuestras sillas Axis plantará un árbol más para Chile.</p>
              `,
          },
        ],
      },
      {
        id: 'la-iluminacion-y-su-influciencia-en-la-productividad',
        categories: ['Ergonomia', 'Tendencias'],
        cover: '/assets/images/blog-page/La-iluminacion-y-su-influencia-en-la-productividad.jpg',
        title: 'La luz y su influencia en la productividad laboral',
        summary: '¿Has notado que en las primeras horas del día tus sentidos están mucho más despiertos y alertas? Descubre cual es la importancia de la luz y que provoca en las personas la temperatura de color.',
        post_date: '26/09/2019',
        rate: '4',
        posts: [
          {
            layout: 'normal',
            img: '',
            subtitle: '¿Has percibido que en las primeras horas del día tus sentidos están mucho más despiertos y alertas? Tienes más fuerzas para subir las escaleras, si cae un lápiz de tu escritorio, probablemente lo recojas inmediatamente…pero ¿cómo sería si pasara durante la tarde?',
            text: `
              <p>Nuestro cuerpo contiene un gran reloj ubicado en un sector del cerebro llamado hipotálamo. Este reloj controla la mayoría de todos los ciclos circadianos de nuestro cuerpo, entendiendo que un ciclo circadiano es todo lo que nuestro cuerpo realiza durante el periodo de 24 horas.</p>
              <p>Existen muchos factores que condicionan nuestro cíclico cuerpo, uno de ellos es la luz. La luz funciona como un indicador determinante para este reloj, que produce distintas hormonas según el color de esta.</p>
              <p>Aparentemente todas las fuentes de luz son blancas, esto no es más que una adaptación que hace nuestro cerebro y que las cámaras fotográficas han replicado con la función “balance de blancos automáticos”, y la verdad es que la luz tiene distintos colores que pueden ser medidos como temperaturas de color (kelvin), que parten desde los 1800K (luz cálida cercana al color rojo) hasta los 16000K (luz fría cercana al color azul).</p>
              `,
          },
          {
            layout: 'normal',
            img: '/assets/images/blog-detail/la-luz-y-su-influencia-en-la-productividad-laboral/La-hora-más-productiva-del-día.jpg',
            subtitle: 'Ahora es cuando todo comienza a tener sentido y podemos entender el comportamiento de nuestro rendimiento según la hora del día.',
            text: `
              <p>Qué lindo son los atardeceres cuando el cielo se pinta de colores rojos y naranjos, es ahí cuando nuestro cerebro a través de la vista capta esta luz cercana a los 1800K y entiende que debe producir melatonina, la hormona que induce nuestro cuerpo al estado de somnolencia y sueño.</p>
              <p>Existen muchos factores que condicionan nuestro cíclico cuerpo, uno de ellos es la luz. La luz funciona como un indicador determinante para este reloj, que produce distintas hormonas según el color de esta.</p>
              <p>Mientras que en un día soleado y despejado podemos tener entre 6000K o 9000K, ese color blanco cercano al celeste le dice a nuestro cerebro que es pleno día por lo tanto nuestro cuerpo genera cortizol, una hormona capaz de mantener un estado de alerta incrementando el nivel de azúcar en la sangre.</p>
              `,
          },
          {
            layout: 'full',
            img: '/assets/images/blog-detail/la-luz-y-su-influencia-en-la-productividad-laboral/La-hora-más-productiva-del-día-para-trabajar-en-oficinas.jpg',
            subtitle: '',
            text: `
              <p>Por esta razón es que durante el mediodía, es cuando nuestra sangre contiene mayor nivel de azúcar por lo tanto nos sentimos más activos y despiertos.</p>
              `,
          },
        ],
      },
      {
        id: 'teletrabajo',
        categories: ['Tendencias'],
        cover: '/assets/images/blog-page/Muebles-y-sillas-de-oficina-en-el-teletrabajo.jpg',
        title: 'Teletrabajo: el desafío que pone a prueba la adaptación y flexibilidad',
        summary: 'La disciplina, la productividad y la eficiencia pondrán a prueba nuestro desempeño. Descubriremos que la capacidad de adaptación y la flexibilidad son las competencias más eficaces de estos tiempos.',
        post_date: '24/03/2020',
        rate: '4',
        posts: [
          {
            layout: 'normal',
            img: '/assets/images/blog-detail/teletrabajo/Muebles-de-oficina.jpg',
            subtitle: '¿UTOPÍA?',
            text: `
            <p>Muchas personas han escuchado alguna vez la idea romántica del trabajo remoto, pensando en ella nada más como una utopía que solo algunas empresas eran capaces de alcanzar. La verdad es que el teletrabajo es una modalidad que desde hace años, muchas compañías lo han adoptado como práctica, creando una filosofía de trabajo que se basa en objetivos y proyectos, beneficiando el ahorro de tiempo en traslados, congestión vial y monetario.</p>
            <p>El año 2020 comenzó con la atemorizante idea de un virus que se expandía a una velocidad muy díficil de alcanzar, esta condición mundial atípica, ha obligado a países completos a paralizar sus actividades cotidianas, modificando también la forma de desempeñar actividades laborales.</p>
            <p>Sin duda alguna, este será el año de experimentar nuevas maneras de comunicación, nuevas vías de organización y de crear nuevos espacios de trabajo que cumplan requisitos actuales.</p>

              <img src="/assets/images/blog-detail/COMILLAS-BLOG.svg">

            <p>Hoy queremos contarte lo que debes saber sobre el trabajo remoto o teletrabajo y cómo esta metología puede seguir siendo tan eficiente como una oficina física.</p>`,
          },
          {
            layout: 'full',
            img: '',
            subtitle: '',
            text: `
            <p>Para comenzar, debemos buscar el lugar idóneo que se convertirá en nuestro espacio de trabajo. Este lugar debe estar alejado de elementos de distracción, como televisores o zonas de estancia común, de esa manera también evitaremos interrupciones.</p>
            `,
         },
         {
            layout: 'normal',
            img: '/assets/images/blog-detail/teletrabajo/Sillas-de-oficina.jpg',
            subtitle: 'MOBILIARIO, PLATAFORMAS Y HORARIOS',
            text: `
              <p>El mobiliario es trascendental. <strong>¿Cómo reunir todo lo que proporciona una oficina en un nuevo espacio de hogar?</strong>. Ante esta situación, nuestro mejor consejo es escoger un mobiliario versátil, adaptable a las exigencias de las tareas laborales, un mobiliario que brinde dinamismo y movilidad, de esa forma poder <a href="https://www.dunati.com.ve/escritorios/lift">alternar la posición corporal</a>.</p>
              <p>Otro elemento de importancia es la <a href="https://www.dunati.com.ve/productos/silla-axis-sin-cabecero">silla</a>, ésta debe tener características <strong>ergonómicas</strong> como regulación de asiento en altura y profundidad, apoyo lumbar y ajuste de brazos.</p>
              <p>Teniendo en cuenta que, estaremos una jornada laboral regular en nuestro puesto, debemos mantener las mismas garantías de ergonomía y bienestar.</p>
              <p>Ante la nueva metodología de trabajo, es primordial fijar reuniones con antelación, son imprescindibles las llamadas telefónicas y la organización del equipo.</p>
              <p>Utilizar plataformas como “Zoom.us” nos da la oportunidad de realizar videoconferencias simultáneas, previamente agendadas por medio de un calendario.</p>
              <p>Fijar horarios de trabajo colaborativo nos dará la oportunidad de trabajar en sintonía con aquellos compañeros con los que compartimos proyectos o metas.</p>
              <p>Trabajar mediante nube <strong>(como Dropbox o Google Drive)</strong> es un excelente método para el intercambio efectivo de archivos y documentos de importancia.</p>
              <p>Debemos generar los conductos regulares de comunicación y las dinámicas de trabajo. </p>
              <p>El trabajo se transformará en objetivos, mediante la planificación y las metas que como equipo se planteen. Es importante llevar a cabo una planificación en donde se mencionen los proyectos y las proyecciones.</p>
              <p>Cada integrante podrá desarrollar su parte del trabajo según el acuerdo que se llevó a cabo.</p>`,
          },
          {
            layout: 'full',
            img: '',
            subtitle: '',
            text: `
              <p>La disciplina, la productividad y la eficiencia pondrán a prueba nuestro desempeño.</p>
              <p>Descubriremos que la capacidad de adaptación y la flexibilidad son las competencias más eficaces de estos tiempos, asimismo en el mobiliario; hoy los muebles deben cumplir con estándares que cumplan las exigencias de una oficina como también de un hogar.</p>
              `,
          },
        ],
      },
      {
        id: 'puede-el-diseno-de-oficina-mejorar-la-eficiencia',
        categories: ['Ergonomia', 'Tendencias'],
        cover: '/assets/images/blog-page/Espacios-productivos-y-muebles-de-oficina.jpg',
        title: '¿Puede el diseño de oficina mejorar la eficiencia?',
        summary: 'Un espacio de trabajo bien diseñado puede facilitar la ejecución de tareas, impulsar la creatividad y tener efectos positivos sobre la salud. ¿Cómo es esto posible?',
        post_date: '26/09/2019',
        rate: '3',
        posts: [
          {
            layout: 'normal',
            img: '',
            subtitle: 'Un espacio de trabajo bien diseñado puede facilitar la ejecución de tareas, impulsar la creatividad, tener efectos positivos sobre la salud y aumentar el nivel de bienestar de las personas.',
            text: `
              <p>Estudios realizados por la Universidad de Harvard concluyeron que las capacidades cognitivas asociadas a actividades como tomar una decisión o prestar atención a una tarea se ven influenciadas significativamente por el entorno.</p>
              <p>¿Cómo es esto posible? El aumento de la productividad puede deberse a factores que faciliten el trabajo mediante las condiciones físicas y estéticas de un espacio, las condiciones de acústica y climatización y también el equipamiento y mobiliario funcional y modular.</p>
              <p>Es importante también crear una atmósfera de productividad en el sitio de trabajo. Las empresas de hoy crean una cultura, valores y objetivos. Entonces, antes de diseñar el espacio de trabajo se debe identificar la misión de la empresa y comunicarla al equipo, para que cada persona conozca y desarrolle su papel en la búsqueda de ese logro. Luego se diseñará un espacio de trabajo que refleje esa cultura, facilite alcanzar la misión y promueva la salud y bienestar.</p>
              <p>Un paso necesario en el diseño de oficinas es analizar las actividades clave de la empresa y traducirlas en espacios integrales y flexibles. La revisión de los procesos de la empresa y cómo se ejecutan permitirá escoger qué espacios incorporar de una lista de posibilidades que incluye: espacios para el trabajo individual donde se requiere mayor concentración, áreas para <a href="https://www.dunati.com.ve/blog/el-mobiliario-de-oficina-tipo-cowork">cowork o trabajo colaborativo</a>, espacios de trabajo manual o talleres, espacios de recepción y atención a clientes, espacios de descanso, alimentación y recreación, entre otros.</p>
              `,
          },
          {
            layout: 'normal',
            img: '/assets/images/blog-detail/puede-el-diseno-de-oficina-mejorar-la-eficiencia/oficinas-eficientes-muebles-ergonomicos.jpg',
            subtitle: '',
            text: `
              <p>Ante el gran rango de posibilidades, cabe preguntarse: ¿Existe una metodología o marco que sirva de mapa a la hora de diseñar espacios de trabajo? ¡La respuesta es sí!. En Dunati, creemos que existen puntos claves que deciden y sirven como camino para llegar a un resultado que refleje funcionalidad, confort y comodidad, contribuyendo al bienestar del equipo.</p>
              <p>A continuación, mencionaremos, lo que consideramos grandes pilares al momento de organizar áreas y espacios de un equipo de trabajo, enfocándonos siempre a convertir el enterno, en un lugar ideal.</p>
              <p>Por medio de un plano arquitectónico podemos evidenciar la <strong>ubicación de suministros básicos</strong> como por ejemplo, agua, cañerias, electricidad, y puntos de electricidad y red. Puntos claves al comenzar una zonificación, aquellas ubicaciones trazan a gran escala las zonas de circulación y zonas de trabajo.</p>
              <p>En tanto la <strong>acústica y climatización</strong> son grandes conceptos a abordar, definiendo calidad de aires, ventilación artificial, sensación térmica óptima y sectores de menor y mayor contaminación acústica. Teniendo un parámetro de ubicación y rendimiento es cómo podemos comenzar la distribución de espacios de trabajo y espacios comunes.</p>
              <p><strong>La iluminación</strong> se divide en dos grandes caminos, la luz artificial y luz natural. Nuestra recomendación es aprovechar eficientemente la luz natural, definiendo áreas en donde la luz natural sea suficiente para un uso óptimo, ya que <a href="https://www.dunati.com.ve/blog/la-iluminacion-y-su-influciencia-en-la-productividad">la luz tiene influencia sobre la productividad laboral</a>. En el caso de zonas de trabajo, es necesario definir la actividad a desarrollar y de esa manera escoger luminarias capaces de brindar el lux recomendado para trabajar.</p>
              <p>Un eje que debe regir ante todo diseño es el nivel de <strong>accesibilidad</strong>. Considerar espacios útiles en pasillos, ejes de giro para sillas de ruedas, rampas y mobiliario con medidas que cumplan las necesidades de movilidad reducidas.</p>
              <p><strong>La arquitectura e interiorismo</strong> debe estar guiada por los valores de la compañía, teniendo en consideración que los elementos visuales como <a href="https://www.dunati.com.ve/blog/el-color-de-la-oficina">el color de la oficina</a>, materialidades o revestimientos, gráfica, entre otros, son el motor de la experiencia tanto para el equipo como para clientes.</p>
              <p>Como último y no menos importante pilar clave es el <strong>mobiliario</strong>. Desde siempre hemos entendido los muebles como necesarios, pero muy pocas veces se fomenta la funcionalidad y la modulación de estos. Debemos comprender que el trabajo de hoy en día se rige por el dinamismo y la versatilidad. Para nosotros, cada persona es un elemento importante, es nuestro deber entregar las herramientas no solo necesarias, sino además las que enriquezcan la productividad, ergonomía y bienestar laboral.</p>
              `,
          },
          {
            layout: 'full',
            img: '/assets/images/blog-detail/puede-el-diseno-de-oficina-mejorar-la-eficiencia/escritorios-ergonomicos-silla-ergonomica.jpg',
            subtitle: '',
            text: `
              <p>¿Qué ocurre cuando no se cumple con condiciones mínimas en estas seis áreas? Estamos en presencia de un espacio de trabajo que puede afectar negativamente tanto al equipo como a las empresa. Las evidencias son contundentes: hay una correlación directa entre buen diseño del espacio y productividad. En consecuencia, el diseño y la planificación del espacio no pueden evitarse si queremos remediar una baja productividad.</p>
              <p>En Dunati estamos convencidos de que la inversión en mejores espacios se recupera cuando consideramos el valor que el cambio agrega en términos de productividad, retención de un equipo valioso y captación de nuevos talentos. Un sitio de trabajo apropiado y cómodo hará que sus colaboradores se sientan más comprometidos y les permitirá alcanzar su máximo potencial de aporte.</p>
              <p>¿Cómo evaluas tu espacio de trabajo en términos de diseño, cultura de la compañía, experiencia de usuario, funcionalidad y ergonomía? Somos un equipo multidisciplinario dispuesto a atender los requerimientos y desafíos que cada proyecto conlleva. En Dunati asesoramos tus ideas.</p>
              `,
          },
        ],
      },
    ],
    series: [
      {
        id: 'versa',
        name: 'versa',
        description: 'versa, escritorios y estaciones',
        categories: ['escritorios', 'estaciones', 'mesas'],
      },
      {
        id: 'diamond',
        name: 'diamond',
        description: 'diamond, escritorios y estaciones',
        categories: ['escritorios', 'estaciones', 'mesas'],
      },
      {
        id: 'nordika',
        name: 'nordika',
        description: 'nordika, escritorios y estaciones',
        categories: ['escritorios', 'estaciones', 'mesas'],
      },
      {
        id: 'lift',
        name: 'lift',
        description: 'lift, escritorios y estaciones',
        categories: ['escritorios', 'estaciones'],
      },
      {
        id: 'legacy',
        name: 'legacy',
        description: 'legacy, escritorios y estaciones',
        categories: ['escritorios', 'mesas'],
      },
      {
        id: 'prisma',
        name: 'prisma',
        description: 'prisma, escritorios y estaciones',
        categories: ['escritorios', 'estaciones', 'mesas'],
      },
      {
        id: 'arista',
        name: 'arista',
        description: 'arista, escritorios y estaciones',
        categories: ['escritorios', 'estaciones', 'mesas'],
      },
      {
        id: 'optima',
        name: 'optima',
        description: 'optima, escritorios y estaciones',
        categories: ['escritorios', 'estaciones'],
      },
    ],
    listProducts: [],
    listSubcategories: []
  },
  getters: {
    sideBar(state) {
      return state.sideBar;
    },
    classMenu(state) {
      return state.classMenu;
    },
    backdrop(state) {
      return state.backdrop;
    },
    search(state) {
      return state.search;
    },
    projects(state) {
      return state.projects;
    },
    blogs(state) {
      return state.blogs;
    },
    series(state) {
      return state.series;
    },
    products(state) {
      return state.listProducts;
    },
    subcategories(state) {
      return state.listSubcategories;
    }
  },
  mutations: {
    toggleSideBar(state, tg) {
      state.sideBar = !state.sideBar;
    },
    changeMenu(state, className) {
      state.classMenu = className;
    },
    changeBackdrop(state) {
      state.backdrop = !state.backdrop;
    },
    changeSearch(state) {
      state.search = !state.search;
    },
    setListProducts(state, list) {
      state.listProducts = list;
    },
    setSubcategories(state, list) {
      state.listSubcategories = list;
    }
  },
  actions: {
    toggleSideBar: ({ commit }) => {
      commit('toggleSideBar', false);
    },
    changeMenu: ({ commit }, className) => {
      commit('changeMenu', className);
    },
    changeBackdrop: ({ commit }) => {
      commit('changeBackdrop', false);
    },
    changeSearch: ({ commit }) => {
      commit('changeSearch', false);
    },
    setListProducts: ({ commit }, list) => {
      commit('setListProducts', list);
    },
    loadSubcategories: ({ commit }) => {
      ApiServices.getProductSubcategories()
        .then((response: AxiosResponse<any>) => {
          const list: Subcategory[] = [];

          response.data.data.forEach((element: any) => {
            if (element) list.push(new Subcategory(element));
          });
          
          commit("setSubcategories", list);
        })
        .catch((error: any) => {})
        .finally(() => {});
    }
  },
});
