import { ApiServices } from '@/services/api.services';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import Vue from 'vue';
import Component from 'vue-class-component';

const cleanMetas = () => {
  return new Promise((resolve, reject) => {
    const items = document.head.querySelectorAll('meta');
    for (const i in items) {
      if (typeof items[i] === 'object' && ['viewport'].findIndex((val) => val === items[i].name) != 0 && items[i].name !== '') {
        document.head.removeChild(items[i]);
      }
    }
    resolve(true);
  });
};

const createMeta = (vm: any, name: any, ...attr: any) => {
  const meta = document.createElement('meta');
  meta.setAttribute(name[0], name[1]);
  for (const i in attr) {
    const at = attr[i];
    for (const k in at) {     
      meta.setAttribute(at[k][0], getString(vm, at[k][1]));
    }
  }
  document.head.appendChild(meta);
};

const getString = (vm: any, content: any) => {
  return typeof content === 'function'
    ? content.call(vm)
    : content;
};

export const getMeta = (vm: any, meta: any, env: any) => {
  if (typeof meta !== 'object') {
    return;
  }

  if (env) {
    return Object.keys(meta)
      .map((value) => {
        return Object.keys(meta[value])
          .map((key) => `${key}="${getString(vm, meta[value][key])}"`)
          .join(' ');
      })
      .map((value) => `  <meta ${value} >`)
      .join('\n');

  } else {
    return meta;
  }
};

export const getImgByType = (img: any, type: any = null) => {
  if (!img) return null;

  if (!type) {
    return ApiServices.strapiUrl + img.url;
  } else {
    if (img.formats && img.formats[type]) {
      return ApiServices.strapiUrl + img.formats[type].url;
    } else {
      return ApiServices.strapiUrl + img.url;
    }
  }
};


export const getJsonld = (objForJson: any, env: any) => {
  if (typeof objForJson !== 'object') {
    return;
  }

  return `<script type="application/ld+json">${JSON.stringify(objForJson, null, '\t')}</script>\n`;
};

const cleanJsonld = () => {
  return new Promise((resolve, reject) => {
    const items = document.head.querySelectorAll('script');
    for (const i in items) {
      if (items[i] && items[i].type === 'application/ld+json') {
        document.head.removeChild(items[i]);
      }
    }

    resolve(true);
  });
};

const createJsonld = (objForJson: any) => {
  const jsonld = document.createElement('script');
  jsonld.setAttribute('type', 'application/ld+json');
  jsonld.appendChild(document.createTextNode(JSON.stringify(objForJson, null, '\t')));

  document.head.appendChild(jsonld);
}

@Component
class ServerHeadMixin extends Vue {
  public created() {
    this.renderTags();
    this.renderJsonld();
  }

  public renderTags(): void {
    const { dunatiHeadTags }: any = this.$options;
    if (dunatiHeadTags) {
      const { title } = dunatiHeadTags;
      if (title) {
        this.$ssrContext.title = `${getString(this, title)}`;
      }

      const { metaTags } = dunatiHeadTags;

      if (metaTags) {
        this.$ssrContext.meta = `\n${getMeta(this, metaTags, true)}`;
      }
    }
  }

  public renderJsonld(): void {
    const { dunatiJsonld }: any = this.$options;

    if (dunatiJsonld) {
      this.$ssrContext.jsonld = `\n${getJsonld(dunatiJsonld, true)}`;
    }
  }
}

@Component
class ClientHeadMixin extends Vue {
  public mounted() {
    this.renderTags();
    this.renderJsonld();
  }

  public renderTags(): void {
    const vm = this;
    const { dunatiHeadTags }: any = this.$options;

    if (dunatiHeadTags) {
      const { title } = dunatiHeadTags;
      if (title) {
        document.title = `${getString(this, title)}`;
      }

      cleanMetas().then(() => {
        const { metaTags } = dunatiHeadTags;
        if (metaTags) {
          for (const nm in metaTags) {
            const name = Object.entries(metaTags[nm])[0];
            const attr = Object.entries(metaTags[nm]).splice(1, Object.entries(metaTags[nm]).length);
            createMeta(vm, name, attr);
          }
        }
      });
    }
  }

  public renderJsonld(): void {
    const { dunatiJsonld }: any = this.$options;

    if (dunatiJsonld) {
      cleanJsonld().then(() => {
        createJsonld(dunatiJsonld);   
      })
    }
  }
}

export const mixingTags: any = process.env.VUE_ENV === 'server'
  ? ServerHeadMixin
  : ClientHeadMixin;
