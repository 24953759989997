import Vue from 'vue';
import Component from 'vue-class-component';
import { ApiServices } from '@/services/api.services';
import { CATCH_KEY } from '@/main';

const components: any = {};

if (typeof window !== 'undefined') {
  // @ts-ignore
  const InvisibleRecaptcha = () => import('vue-invisible-recaptcha');
  components['invisible-recaptcha'] = InvisibleRecaptcha;
}

@Component({
  components,
})
export default class FooterComponent extends Vue {
  public lgModal = false;
  public direction = '';
  public data = {
    name: '',
    email: '',
  };
  public emailSend = false;
  public loading = false;
  public sendingEmail = false;
  public errorEmail = false;
  public sendText = 'Enviar';
  public errorMsg = '';
  public privateKey = CATCH_KEY;

  public mounted() {
  }

  public Close(modal: string) {
    this.$root.$emit('bv::hide::modal', modal);
    this.data = {
      name: '',
      email: '',
    };
  }

  public toDunati() {
    if (this.direction !== '') {
      window.open(`https://maps.google.com/maps?saddr=${this.direction}&daddr=DUNATI+Muebles+de+Oficina+-+Del+Valle+932,+Huechuraba,+Región+Metropolitana,+Chile`, '_blank');
    }
  }

  public showModel(modal: string) {
    if (modal === 'modal-subscription' && this.data.email === '') {
      return;
    }
    (this.$refs[`${modal}`] as any).show();
  }

  public titleCase(str: string) {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  public sendMail() {
    if (this.data.name !== '' && this.data.email !== '' && this.validEmail(this.data.email)) {
      this.sendingEmail = true;
      this.loading = true;
      const msg = `<h3>Solicitud de suscripción</h3>
      <p>De: ${this.titleCase(this.data.name)}</p>
      <p>Correo: ${this.data.email}</p>
      <br>`;

      let conversionUrl = '';
      if (typeof window !== 'undefined') {
        conversionUrl = window.entryUrl;
      }

      const body = {
        notificationsFrom: `${this.titleCase(this.data.name)}`,
        msg,
        subject: `Solicitud de suscripción de: ${this.titleCase(this.data.name)}`,
        contact: {
          email: this.data.email,
          name: this.titleCase(this.data.name),
          source: `${window.location.pathname.substring(1)}`,
          conversionUrl,
        },
      };

      ApiServices.sendMail(body).then((response) => {
        if (response) {
          ApiServices.contactCliengo({
            name: this.titleCase(this.data.name),
            email: this.data.email,
          }).then((res) => {
            console.log(res);
          }).catch((error) => {
            console.log(error);
          });

          this.data = {
            name: '',
            email: '',
          };
          this.sendText = '¡Listo!';
          this.emailSend = true;
          this.sendingEmail = false;
          this.loading = false;

          setTimeout(() => {
            this.Close('modal-subscription');
          }, 5000);
        }
      });
    } else {
      this.errorEmail = true;
      if (!this.validEmail(this.data.email)) {
        this.errorMsg = 'El correo electrónico debe ser válido.';
      } else if (this.data.name === '') {
        this.errorMsg = 'El campo nombre es requerido.';
      } else if (this.data.email === '') {
        this.errorMsg = 'El campo correo es requerido.';
      }

      setTimeout(() => {
        this.errorEmail = false;
        this.errorMsg = '';
      }, 20000);

    }
  }

  public validEmail(email: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  public callbackTest(recaptchaToken: any) {
    if (recaptchaToken) {
      this.sendMail();
    }
  }
}

