import Vue from 'vue';
import Router from 'vue-router';

// avoid scroll to top by names
const avoidScrollList: string [] = ['products-chair','products-multipurpose-chair','products-table','products-storage'];

Vue.use(Router);

export default new Router({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'index',
      component: () => import(/* webpackChunkName: "index" */ './views/index-page/index-page.component.vue'),
    }, {
      path: '/404',
      name: '404',
      // tslint:disable-next-line: max-line-length
      component: () => import(/* webpackChunkName: "not-found" */ './views/not-found-page/not-found-page.component.vue'),
    }, {
      path: '/productos',
      name: 'products',

      component: () => import(/* webpackChunkName: "products" */ './views/products-page/products-page.component.vue'),
    }, {
      path: '/escritorios',
      name: 'products-desk',
      props: {
        category: 'escritorios',
      },
      // tslint:disable-next-line: max-line-length
      component: () => import(/* webpackChunkName: "products-desk" */ './views/desk-category-page/desk-category-page.component.vue'),
    }, {
      path: '/escritorios/nordika',
      name: 'products-desk-nordika',
      // tslint:disable-next-line: max-line-length
      component: () => import(/* webpackChunkName: "products-desk-nordika" */ './views/series-page/serie-nordika-page/serie-nordika-page.component.vue'),
    }, {
      path: '/escritorios/arista',
      name: 'products-desk-arista',
      // tslint:disable-next-line: max-line-length
      component: () => import(/* webpackChunkName: "products-desk-arista" */ './views/series-page/serie-arista-page/serie-arista-page.component.vue'),
    }, {
      path: '/escritorios/optima',
      name: 'products-desk-optima',
      // tslint:disable-next-line: max-line-length
      component: () => import(/* webpackChunkName: "products-desk-optima" */ './views/series-page/serie-optima-page/serie-optima-page.component.vue'),
    }, {
      path: '/escritorios/versa',
      name: 'products-desk-versa',
      // tslint:disable-next-line: max-line-length
      component: () => import(/* webpackChunkName: "products-desk-versa" */ './views/series-page/serie-versa-page/serie-versa-page.component.vue'),
    }, {
      path: '/escritorios/diamond',
      name: 'products-desk-diamond',
      // tslint:disable-next-line: max-line-length
      component: () => import(/* webpackChunkName: "products-desk-diamond" */ './views/series-page/serie-diamond-page/serie-diamond-page.component.vue'),
    }, {
      path: '/escritorios/training',
      name: 'products-desk-training',
      // tslint:disable-next-line: max-line-length
      component: () => import(/* webpackChunkName: "products-desk-training" */ './views/series-page/serie-training-page/serie-training-page.component.vue'),
    }, {
      path: '/escritorios/lift',
      name: 'products-desk-lift',
      // tslint:disable-next-line: max-line-length
      component: () => import(/* webpackChunkName: "products-desk-lift" */ './views/series-page/serie-lift-page/serie-lift-page.component.vue'),
    }, {
      path: '/escritorios/legacy',
      name: 'products-desk-legacy',
      // tslint:disable-next-line: max-line-length
      component: () => import(/* webpackChunkName: "products-desk-legacy" */ './views/series-page/serie-legacy-page/serie-legacy-page.component.vue'),
    }, {
      path: '/escritorios/prisma',
      name: 'products-desk-prisma',
      // tslint:disable-next-line: max-line-length
      component: () => import(/* webpackChunkName: "products-desk-prisma" */ './views/series-page/serie-prisma-page/serie-prisma-page.component.vue'),
    }, {
      path: '/recepciones',
      name: 'products-recepciones-magic',
      // tslint:disable-next-line: max-line-length
      component: () => import(/* webpackChunkName: "products-recepciones-magic" */ './views/series-page/serie-magic-page/serie-magic-page.component.vue'),
    }, {
      path: '/sillas-de-oficina/:subcategory?', // al cambiar esta ruta, cambiarla en product-detail-page
      name: 'products-chair',
      props: {
        category: 'sillas',
      },
      // tslint:disable-next-line: max-line-length
      component: () => import(/* webpackChunkName: "products-chair" */ './views/products-list/products-list.component.vue'),
    }, {
      path: '/sillas-de-oficina/:subcategory/:id', // al cambiar esta ruta, cambiarla en product-detail
      name: 'products-chair-details',
      props: {
        category: 'sillas',
      },
      // tslint:disable-next-line: max-line-length
      component: () => import(/* webpackChunkName: "products-chair-details" */ './views/product-details/product-details.component.vue'),
    }, {
      path: '/sofas-y-sillas-multiuso/:subcategory?', // al cambiar esta ruta, cambiarla en product-detail-page
      name: 'products-multipurpose-chair',
      props: {
        category: 'sofas y sillas multiuso',
      },
      // tslint:disable-next-line: max-line-length
      component: () => import(/* webpackChunkName: "products-chair-details" */ './views/products-list/products-list.component.vue'),
    }, {
      path: '/sofas-y-sillas-multiuso/:subcategory/:id', // al cambiar esta ruta, cambiarla en product-detail
      name: 'products-multipurpose-chair-details',
      props: {
        category: 'sofas y sillas multiuso',
      },
      // tslint:disable-next-line: max-line-length
      component: () => import(/* webpackChunkName: "products-chair-details" */ './views/product-details/product-details.component.vue'),
    }, {
      path: '/mesas/:subcategory?', // al cambiar esta ruta, cambiarla en product-detail-page
      name: 'products-table',
      props: {
        category: 'mesas',
      },
      // tslint:disable-next-line: max-line-length
      component: () => import(/* webpackChunkName: "products-table" */ './views/products-list/products-list.component.vue'),
    }, {
      path: '/mesas/:subcategory/:id', // al cambiar esta ruta, cambiarla en product-detail
      name: 'products-table-details',
      props: {
        category: 'mesas',
      },
      // tslint:disable-next-line: max-line-length
      component: () => import(/* webpackChunkName: "products-table-details" */ './views/product-details/product-details.component.vue'),
    }, {
      path: '/almacenamiento/:subcategory?', // al cambiar esta ruta, cambiarla en product-detail-page
      name: 'products-storage',
      props: {
        category: 'almacenamiento',
      },
      // tslint:disable-next-line: max-line-length
      component: () => import(/* webpackChunkName: "products-storage" */ './views/products-list/products-list.component.vue'),
    }, {
      path: '/almacenamiento/:subcategory/:id', // al cambiar esta ruta, cambiarla en product-detail-page
      name: 'products-storage-details',
      props: {
        category: 'almacenamiento',
      },
      // tslint:disable-next-line: max-line-length
      component: () => import(/* webpackChunkName: "products-storage-details" */ './views/product-details/product-details.component.vue'),
    }, {
      path: '/productos/:id',
      name: 'products-detail',
      // tslint:disable-next-line: max-line-length
      component: () => import(/* webpackChunkName: "products-detail" */ './views/product-detail-page/product-detail-page.component.vue'),
    }, {
      path: '/blog',
      name: 'blog',
      component: () => import(/* webpackChunkName: "blog" */ './views/blog/blog.component.vue'),
    },
    {
      path: '/blog/:slug',
      name: 'blogs-detail',
      // tslint:disable-next-line: max-line-length
      component: () => import(/* webpackChunkName: "blog" */ './views/blog-detail-page/blog-detail-page.component.vue'),
    },
    {
      path: '/contacto',
      name: 'contact',
      component: () => import(/* webpackChunkName: "contact" */ './views/contact-page/contact-page.component.vue'),
    },
    {
      path: '/proyectos',
      name: 'projects-categories',
      // tslint:disable-next-line: max-line-length
      component: () => import(/* webpackChunkName: "projects-categories" */ './views/projects-categories-page/projects-categories-page.component.vue'),
    },
    {
      path: '/proyectos/:slug',
      name: 'project-details',
      // tslint:disable-next-line: max-line-length
      component: () => import(/* webpackChunkName: "project-details" */ './views/project-details/project-details.component.vue'),
    },
    {
      path: '/nosotros',
      name: 'about-dunati',
      component: () => import(/* webpackChunkName: "about-dunati" */ './views/about-us/about-us.component.vue'),
    },
    {
      path: '/ficha-de-cliente',
      name: 'ficha-de-cliente',
      component: () => import(/* webpackChunkName: "ficha-de-cliente" */ './views/customer-request/customer-request.component.vue'),
    },
    {
      path: '/trabaja-en-dunati',
      name: 'trabaja-en-dunati',
      component: () => import(/* webpackChunkName: "trabaja-en-dunati" */ './views//work-request/work-request.component.vue'),
    },
    {
      path: '*',
      component: () => import(/* webpackChunkName: "trabaja-en-dunati" */ './views/not-page/not-page.component.vue'),
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else if (
      avoidScrollList.includes(from.name || '') &&
      avoidScrollList.includes(to.name || '')
    ) {
      return;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
