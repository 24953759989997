import { render, staticRenderFns } from "./layout.component.html?vue&type=template&id=4bf9c2f6&scoped=true&"
import script from "./layout.component.ts?vue&type=script&lang=ts&"
export * from "./layout.component.ts?vue&type=script&lang=ts&"
import style0 from "./layout.component.scss?vue&type=style&index=0&id=4bf9c2f6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bf9c2f6",
  null
  
)

export default component.exports