









import { Component, Vue } from "vue-property-decorator";
import LayoutComponent from "./components/layout/layout.component";

@Component({
  components: { LayoutComponent }
})
export default class App extends Vue {
  public mounted() {
    // const cliengo = document.createElement("script");
    // cliengo.innerHTML =`(function () { var ldk = document.createElement('script'); ldk.type = 'text/javascript'; ldk.async = true; ldk.src = 'https://s.cliengo.com/weboptimizer/57e13adbe4b003415245fef1/57f1794be4b0c178405d2098.js?platform=view_installation_code'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ldk, s); })();`;
    // document.head.appendChild(cliengo);
  }
}
