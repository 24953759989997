const _ = require('lodash');

export class Subcategory {
  public id!: number;
  public name!: string;
  public slug!: string;
  public text!: string;
  public metaDescription!: string;

  public category!: string;

  public updatedAt!: string;
  public createdAt!: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.slug = data.slug;
      this.text = data.text;
      this.metaDescription = data.meta_description || '';

      this.category = _.get(data, 'category.name', '');
      
      this.updatedAt = data.updatedAt;
      this.createdAt = data.createdAt;
    }
  }
}
