import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ApiServices } from '@/services/api.services';
import router from '@/router';
import DunatiLoaderComponent from '@/components/dunati-loader/dunati-loader.component';
import { Category } from '@/class/category';

@Component({
  components: {
    DunatiLoaderComponent,
  },
})
export default class SearchComponent extends Vue {
  @Prop({ default: '' })
  // @ts-ignore
  public data: string;

  public categories = ['series', 'productos', 'proyectos', 'blog'];
  public items: any = [];
  public tokeepItems: any = [];
  public current: any = null;
  public loading: boolean = false;
  public itemsPeerPage: number = 5;
  public toShow: number = 5;

  public productSlugs = [
    {
      category: 'sillas',
      slug: 'sillas-de-oficina',
      urlName: 'products-chair-details'
    },
    {
      category: 'mesas',
      slug: 'mesas',
      urlName: 'products-chair-details'
    },
    {
      category: 'almacenamiento',
      slug: 'almacenamiento',
      urlName: 'products-storage-details'
    },
    {
      category: 'sofas y sillas multiuso',
      slug: 'sofas-y-sillas-multiuso',
      urlName: 'products-multipurpose-chair-details'
    },
  ]

  @Watch('data')
  public onPropertyChanged(value: string, oldValue: string) {
    this.onSearch();
  }

  public mounted() {}

  public onSearch() {
    if (this.data !== '') {
      this.loading = true;
      this.current = null;
      const dataSearch = this.data.toLowerCase();
      this.toShow = this.itemsPeerPage;
      ApiServices.searchAll(`search=${dataSearch}`).then(async (response: any) => {

        const check = (category: any) => category.indexOf(dataSearch) > -1;
        const series = this.$store.getters.series.filter((serie: any) => (serie.name.toLowerCase().indexOf(dataSearch) > -1) || serie.categories.some(check));

        this.items = [];
        this.tokeepItems = [];

        await series.forEach((element: any) => {
          this.items.push({
            id: element.id,
            category: 'series',
            name: element.name,
            description: element.description,
            url: `escritorios/${element.id}`,
            urlName: `products-desk-${element.name.toLowerCase()}`,
          });
        });

        await response[0].data.data.forEach((element: any) => {
          this.items.push({
            id: element.slug,
            category: 'productos',
            subcategory: element.subcategory.slug,
            name: element.name,
            description: element.description,
            url: `${this.getProductCategory(element.category)}/${element.subcategory.slug}/${element.slug}`,
            urlName: `${this.getProductUrlName(element.category)}`,
          });
        });

        await response[2].data.data.forEach((element: any) => {
          this.items.push({
            id: element.slug,
            category: 'proyectos',
            name: element.name,
            description: element.sub_name,
            url: `proyectos/${element.slug}`,
            urlName: 'project-details',
          });
        });

        await response[1].data.data.forEach((element: any) => {
          this.items.push({
            id: element.slug,
            category: 'blog',
            name: element.title,
            description: element.summary,
            url: `blog/${element.slug}`,
            urlName: 'blogs-detail',
          });
        });

        this.tokeepItems = this.items;
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
      });
    }
  }

  public showCategory(category: string) {
    if (category) {
      this.current = category;
      this.items = this.tokeepItems.filter((x: any) => x.category === category);
    } else {
      this.current = null;
      this.items = this.tokeepItems;
    }
  }

  public toUrl(name: string, id: any, extras = {}) {
    this.$store.dispatch('changeSearch');
    if (name === 'blogs-detail' || name === 'project-details') {
      router.push({ name, params: { slug: id }});
    } else {
      router.push({ name, params: { id, ...extras }});
    }

  }

  public loadMore() {
    this.toShow += this.itemsPeerPage;
  }

  getProductCategory(category: Category) {
    let aux = this.productSlugs.find(x => x.category === category.name);
    if (aux && aux.slug) {
        return aux.slug
    } else {
      return '';
    }
  }

  getProductUrlName(category: Category) {
    let aux = this.productSlugs.find(x => x.category === category.name);
    if (aux && aux.urlName) {
        return aux.urlName
    } else {
      return '';
    }
  }
}
