import Vue from 'vue';
import Component from 'vue-class-component';
import MenuHeaderComponent from './menu-header/menu-header.component';
import FooterComponent from './footer/footer.component';
import SidebarComponent from './sidebar/sidebar.component';

@Component({
  components: {
    MenuHeaderComponent,
    FooterComponent,
    // MenuHeaderComponent: () => import(/* webpackChunkName: "header-menu" */  "./menu-header/menu-header.component"),
    // FooterComponent: () => import(/* webpackChunkName: "footer" */  "./footer/footer.component"),
  },
})
export default class LayoutComponent extends Vue {
  public fixedMenu = false;
  public menuup = false;

  private timer: any;
  private entry: boolean = false;
  private lastScrollTop = 0;

  public mounted() {
    window.addEventListener('scroll', this.handleScroll);
  }

  public created() {
  }

  public destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  get statusSideBar() {
    return this.$store.getters.sideBar;
  }

  public handleScroll(event: Event) {
    if (typeof window !== 'undefined') {
      const st = window.pageYOffset || document.documentElement.scrollTop;

      if (st > 0) {
        this.fixedMenu = true;
      } else {
        this.fixedMenu = false;
      }

      if (st > this.lastScrollTop) {
        this.menuup = false;
      } else {
        if (window.pageYOffset <= 0) {
          this.fixedMenu = false;
          this.menuup = false;
        } else {
          this.menuup = true;
          this.fixedMenu = true;
        }
      }
      this.lastScrollTop = st <= 0 ? 0 : st;
    }
  }
}

