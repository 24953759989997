export const DUNATI_DOMAIN: string = process.env.NODE_ENV === 'production' ? 'https://www.dunati.com.ve' : 'https://beta.dunati.com.ve';
export const ROBOT_CONTENT: string = process.env.NODE_ENV === 'production' ? 'index,follow' : 'noindex,nofollow';
export const CATCH_KEY: string = '6LchoMEUAAAAAN2Aqiy__h6yfNMXFt2-9Nu7R2pB';
export const DUNATI_STORE_PRODUCTS: string = 'https://store.dunati.com/?utm_source=www.dunati.com.ve&utm_medium=boton%20productos&utm_campaign=tienda%20online%20abril%202020';
export const DUNATI_ORGANIZATION_SCHEMA: object = {
  "@context":"https://schema.org",
  "@type": "Organization",
  "@id": `${DUNATI_DOMAIN}/#organization`,
  "name": "Dunati",
  "url": `${DUNATI_DOMAIN}`,
  "sameAs": [
    "https://www.facebook.com/dunatioffice/",
    "https://g.page/dunati?gm",
    "https://www.instagram.com/dunatioffice/",
    "https://www.linkedin.com/company/dunati/",
    "https://twitter.com/dunatioffice"
  ],
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "Puerto La Cruz",
    "addressRegion": "Anzoategui",
    "postalCode": "8581122",
    "addressCountry": "VE"
  },
  "logo": {
    "@type": "ImageObject",
    "@id": `${DUNATI_DOMAIN}/#logo`,
    "url": `${DUNATI_DOMAIN}/assets/images/logo_oscuro.svg`,
    "width": 400,
    "height": 200,
    "caption": "Dunati",
    "inLanguage": "es"
  },
  "image": {
    "@id": `${DUNATI_DOMAIN}/#logo`
  },
}

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';

declare global {
  interface Window {
    $: any;
    jQuery: any;
    fn: any;
    entryUrl: string;
  }
}

// @ts-ignore
import VueMq from 'vue-mq';

// import font-awesome
import { faAngleRight, faLock, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { library as lbw } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueLazyLoad from 'vue-lazyload';
// @ts-ignore
import VueMasonry from 'vue-masonry-css';
// @ts-ignore
import { BModal } from 'bootstrap-vue';
// @ts-ignore
import { VBModal } from 'bootstrap-vue';
const VueScrollTo = require('vue-scrollto');
import { sync } from 'vuex-router-sync';
import { mixingTags } from './util/head';
const marked = require('marked');

if (typeof window !== 'undefined') {
  // @ts-ignore
  if (!window.__PRERENDER_INJECTED) {
    window.$ = window.jQuery = require('jquery');
    window.fn = require('owl.carousel');
  }

}

// font-awesome config
lbw.add(faAngleRight);
lbw.add(faLock);
lbw.add(faChevronLeft);
lbw.add(faChevronRight);

Vue.mixin(mixingTags);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('b-modal', BModal);
Vue.directive('b-modal', VBModal);
Vue.directive('focus', {
  inserted(el) {
    el.focus();
  },
});
Vue.use(VueMasonry);
Vue.use(VueMq, {
  breakpoints: {
    xs: 575.98,
    sm: 767.98,
    md: 991.98,
    lg: 1199.98,
    xl: Infinity,
  },
});
Vue.use(VueLazyLoad);
Vue.use(VueScrollTo, { duration: 1200 });

if (typeof window !== 'undefined') {
  // @ts-ignore
  if (!window.__PRERENDER_INJECTED) {
    // @ts-ignore
    const VueGtm = () => import('vue-gtm');
    VueGtm().then((d) => {
      console.log(d.default);
      Vue.use(d.default, {
        id: 'GTM-NNJKLNT',
        // queryParams: {
        //   gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
        //   gtm_preview: 'env-4',
        //   gtm_cookies_win: 'x'
        // },
        enabled: true,
        debug: true,
        loadScript: true,
        vueRouter: router,
      });
    });
  }
}

Vue.config.productionTip = false;

Vue.filter('formatMoney', function(number: any, fixed: number = 0, sections: number) {
  if (!number) { return 0; }

  const re = '\\d(?=(\\d{' + (sections || 3) + '})+' + (fixed > 0 ? '\\.' : '$') + ')';
  return (number).toFixed(Math.max(0, ~~fixed)).replace(new RegExp(re, 'g'), '$&.');
});

Vue.filter('markdownToHtml', function (markdown: string) {
  if (markdown == null) return '';

  return marked(markdown);
});

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next('/404');
  } else {
    next();
  }

  if (typeof window !== 'undefined' && !window.entryUrl && window.location.href) {
    window.entryUrl = window.location.href;
  }
});

export function createApp(context: any) {
  sync(store, router);

  const app = new Vue({
    router,
    store,
    render: (h) => h(App),
  });

  return { app, router, store };
}
